import { render, staticRenderFns } from "./circularStrategy2.vue?vue&type=template&id=110c5836&scoped=true&"
import script from "./circularStrategy2.vue?vue&type=script&lang=ts&"
export * from "./circularStrategy2.vue?vue&type=script&lang=ts&"
import style0 from "./circularStrategy2.vue?vue&type=style&index=0&id=110c5836&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "110c5836",
  null
  
)

export default component.exports