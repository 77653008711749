<template>
  <div class="circularStrategy">
    <!-- 交易详情 -->
    <van-nav-bar
      :title="dataInfo.type ? $t('system.' + dataInfo.type) : ''"
      left-arrow
      @click-left="onClickLeft"
      :right-text="$t('system.TransactionRecord')"
      @click-right="onclickRight"
      safe-area-inset-top
      fixed
      z-index="9999"
    />
    <div class="g_safe_top">
      <div class="layoutBox bgGray">
        <div class="err_msg" v-if="dataInfo.error != ''">
          {{ dataInfo.error }}
        </div>
        <div class="msg_list">
          <div class="contWhiteBox content circular">
            <div class="csTitle" v-if="dataInfo.type == 'wave_f'">
              {{ dataInfo.currency_pair }}
              <span class="gray1" v-if="strategy.v8 == 2">{{
                $t("system.zuokong")
              }}</span>
              <span class="gray1" v-if="strategy.v8 == 2"
                >x{{ strategy.v7 }}</span
              >
              <span class="gray2" v-if="strategy.v8 == 1">{{
                $t("system.zuoduo")
              }}</span>
              <span class="gray2" v-if="strategy.v8 == 1"
                >x{{ strategy.v7 }}</span
              >
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'wave_f_x_double'">
              {{ dataInfo.currency_pair }}
              <span class="gray1" v-if="strategy.v4 == 2">{{
                $t("system.zuokong")
              }}</span>
              <span class="gray1" v-if="strategy.v4 == 2"
                >x{{ strategy.v3 }}</span
              >
              <span class="gray2" v-if="strategy.v4 == 1">{{
                $t("system.zuoduo")
              }}</span>
              <span class="gray2" v-if="strategy.v4 == 1"
                >x{{ strategy.v3 }}</span
              >
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'wave'">
              {{ dataInfo.currency_pair }}
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'wave_f_long'">
              {{ dataInfo.currency_pair }}
              <span class="gray2">{{ $t("system.zuoduo") }}</span>
              <span class="gray2">x{{ strategy.v7 }}</span>
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'wave_f_short'">
              {{ dataInfo.currency_pair }}
              <span class="gray1">{{ $t("system.zuokong") }}</span>
              <span class="gray1">x{{ strategy.v7 }}</span>
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'manual_f_long'">
              {{ dataInfo.currency_pair }}
              <span class="gray2">{{ $t("system.zuoduo") }}</span>
              <span class="gray2">x{{ strategy.v1 }}</span>
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'manual_f_short'">
              {{ dataInfo.currency_pair }}
              <span class="gray1">{{ $t("system.zuokong") }}</span>
              <span class="gray1">x{{ strategy.v1 }}</span>
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'wangge_f'">
              {{ dataInfo.currency_pair }}
              <span class="gray">{{
                $t("system." + dataInfo.strategy_en)
              }}</span>
              <span class="gray1" v-if="strategy.v10 == 2">{{
                $t("system.zuokong")
              }}</span>
              <span class="gray1" v-if="strategy.v10 == 2"
                >x{{ strategy.v9 }}</span
              >
              <span class="gray2" v-if="strategy.v10 == 1">{{
                $t("system.zuoduo")
              }}</span>
              <span class="gray2" v-if="strategy.v10 == 1"
                >x{{ strategy.v9 }}</span
              >
            </div>
            <div class="csTitle" v-if="dataInfo.type == 'wave_f_coin'">
              {{ dataInfo.currency_pair }}
              <span class="gray1" v-if="strategy.v8 == 2">{{
                $t("system.zuokong")
              }}</span>
              <span class="gray1" v-if="strategy.v8 == 2"
                >x{{ strategy.v7 }}</span
              >
              <span class="gray2" v-if="strategy.v8 == 1">{{
                $t("system.zuoduo")
              }}</span>
              <span class="gray2" v-if="strategy.v8 == 1"
                >x{{ strategy.v7 }}</span
              >
            </div>
            <div
              class="csTitle"
              v-if="
                dataInfo.type == 'wangge' ||
                dataInfo.type == 'wangge2' ||
                dataInfo.type == 'dibu_gua'||
                dataInfo.type == 'wangge_m'||
                dataInfo.type == 'wangge_x'
              "
            >
              {{ dataInfo.currency_pair }}
              <span class="gray">{{
                $t("system." + dataInfo.strategy_en)
              }}</span>
            </div>

            <div class="head_list" v-if="dataInfo.type == 'double'">
              <div class="numItem">
                <span>{{ dataInfo.long | danwei3 }}</span>
                <p>{{ $t("system.duocang") }}({{ $t("system.Contract") }})</p>
              </div>
              <div class="numItem3">
                <span>{{ dataInfo.short | danwei3 }}</span>
                <p>{{ $t("system.kongcang") }}({{ $t("system.Contract") }})</p>
              </div>
              <div class="numItem2">
                <span>{{
                  Number(dataInfo.long || 0) + Number(dataInfo.short || 0)
                }}</span>
                <p>{{ $t("system.zcc") }}({{ $t("system.Contract") }})</p>
              </div>
            </div>
            <div class="head_list" v-else>
              <div class="numItem">
                <span>{{ dataInfo.num_ }}</span>
                <p>
                  {{ $t("system.PositionAmount") }}（{{
                    dataInfo.currency_pair | danwei
                  }}）
                </p>
              </div>
              <div class="numItem3">
                <span>{{ dataInfo.avg_price | danwei3 }}</span>
                <p>{{ $t("system.AveragePositionPrice") }}</p>
              </div>
              <div class="numItem2">
                <span>{{ dataInfo.times }}</span>
                <p>{{ $t("system.Numberofpositionsfilled") }}</p>
              </div>
            </div>
            <div class="head_list">
              <div class="numItem1" v-if="fangshi == 1">
                <span>{{ dataInfo.num }}</span>
                <p>
                  {{ $t("system.PositionQuantity") }}（{{ dataInfo.token }}）
                </p>
              </div>
              <div class="numItem1" v-if="fangshi == 2">
                <span v-if="dataInfo.type == 'double'">{{
                  Number((Number(dataInfo.long || 0) -
                  Number(dataInfo.short || 0)).toFixed(2))
                }}</span>
                <span v-else>{{ dataInfo.num }}</span>

                <p v-if="dataInfo.type == 'double'">
                  {{ $t("system.toucun") }}({{ $t("system.Contract") }})
                </p>
                <p v-else-if="dataInfo.type !== 'wave_f_coin'">
                  {{ $t("system.PositionQuantity") }}（{{
                    $t("system.Contract")
                  }}）
                </p>
                <p v-else>
                  {{ $t("system.PositionQuantity") }}（{{ dataInfo.token }}）
                </p>
              </div>
              <div class="numItem3">
                <!--  | danwei3 -->
                <span>{{ dataInfo.last || 0 }}</span>
                <p>{{ $t("system.CurrentPrice") }}</p>
              </div>
              <div
                class="numItem2"
                v-if="
                  dataInfo.type === 'wave' ||
                  dataInfo.type === 'wave_f' ||
                  dataInfo.type === 'wangge_f' ||
                  dataInfo.type === 'wave_f_long' ||
                  dataInfo.type === 'wave_f_short' ||
                  dataInfo.type === 'wave_f_coin' ||
                  dataInfo.type === 'wave_f_x_double' ||
                  dataInfo.type === 'double'
                "
              >
                <span>{{ dataInfo.win_times || 0 }}</span>
                <p>{{ $t("system.zhiyingcishu") }}</p>
              </div>
              <div class="numItem2" v-else>
                <span>{{ (dataInfo.win_rate * 100) | ratioFormat }}%</span>
                <p>{{ $t("system.Up") }}</p>
              </div>
            </div>
            <div class="csTitle1">
              {{ $t("system.AccumulatedProfit") }}
              <span v-if="dataInfo.type !== 'wave_f_coin'">
                {{ dataInfo.total_profit | danwei3 }}
                {{ dataInfo.currency_pair | danwei }}
              </span>
              <span v-else>
                {{ dataInfo.total_profit | danwei3 }}
                {{ dataInfo.token }}</span
              >
            </div>
          </div>

          <!-- 策略相关 -->
          <div class="contWhiteBox content strategy">
            <div class="csTitle">
              {{ $t("system.StrategyRelated") }}
              <span>{{
                dataInfo.type ? $t("system." + dataInfo.type) : ""
              }}</span>
            </div>
            <van-row v-if="dataInfo.type === 'wangge'">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Replenishmenttimes") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitstopcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
                <span class="span1">{{
                  $t("system.Marginofreplenishment")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v6 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Makeupcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v7 }}</span>
                <span class="span1">{{
                  $t("system.Horizontalpushornot")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v8 }}</span>
                <span class="span1">{{ $t("system.bucangbeishu") }}</span>
              </van-col>
            </van-row>
						<van-row v-if="dataInfo.type === 'wangge_x'">
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v1 }}</span>
								<span class="span1">{{ $t('system.OpeningAmount') }}</span>
							</van-col>
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v2 }}</span>
								<span class="span1">{{ $t('system.Replenishmenttimes') }}</span>
							</van-col>
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
								<span class="span1">{{ $t('system.Profitmargin') }}</span>
							</van-col>
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
								<span class="span1">{{ $t('system.Profitstopcallback') }}</span>
							</van-col>
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
								<span class="span1">{{ $t('system.Marginofreplenishment') }}</span>
							</van-col>
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v6 }}</span>
								<span class="span1">{{ $t('system.bucangbeishu') }}</span>
							</van-col>
							<van-col span="8" class="strategyItem">
								<span class="span2">{{ strategy.v31 | ratioFormat }}%</span>
								<span class="span1">{{ $t('system.Makeupcallback') }}</span>
							</van-col>
						</van-row>
            <van-row v-if="
              dataInfo.type === 'wangge2'||
              dataInfo.type == 'dibu_gua'
            ">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Cyclesetting") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.DeclineRange") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}</span>
                <span class="span1">{{ $t("system.Replenishmenttimes") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v6 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitstopcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v7 | ratioFormat }}%</span>
                <span class="span1">{{
                  $t("system.Marginofreplenishment")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v8 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Makeupcallback") }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'wave'">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Zuidabucangcishu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.OneProfitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Wanggekuandu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v5 | ratioFormat }}</span>
                <span class="span1">{{ $t("system.Suofanglv") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v6 | ratioFormat }}</span>
                <span class="span1">{{
                  $t("system.Bucangjinersuofanglv")
                }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'wave_f_x_double'">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Zuidabucangcishu") }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'wangge_m'">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Replenishmenttimes") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitstopcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
                <span class="span1">{{
                  $t("system.Marginofreplenishment")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v12 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Makeupcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v13 }}</span>
                <span class="span1">{{
                  $t("system.Horizontalpushornot")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v14 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.pingtuifudu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v15 }}</span>
                <span class="span1">{{ $t("system.bucangbeishu") }}</span>
              </van-col>
            </van-row>
            <van-row
              v-if="
                dataInfo.type === 'wave_f' ||
                dataInfo.type === 'wave_f_long' ||
                dataInfo.type === 'wave_f_short' ||
                dataInfo.type === 'wave_f_coin'
              "
            >
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Zuidabucangcishu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.OneProfitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Wanggekuandu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v5 | ratioFormat }}</span>
                <span class="span1">{{ $t("system.Suofanglv") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">x{{ strategy.v7 }}</span>
                <span class="span1">{{ $t("system.gangganbeishu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem" style="padding-right: 0">
                <span class="span2">{{ strategy.v6 | ratioFormat }}</span>
                <span class="span1">{{
                  $t("system.Bucangjinersuofanglv")
                }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'double'">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.kcl") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.zdcw") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
                <span class="span1">{{
                  $t("system.Marginofreplenishment")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">x{{ strategy.v5 }}</span>
                <span class="span1">{{ $t("system.gangganbeishu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">
                  {{
                    strategy.v6 && strategy.v6 != 0
                      ? strategy.v6
                      : computedEstimatedMargin()
                  }}
                </span>
                <span class="span1">{{ $t("system.estimatedMargin") }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'wangge_f'">
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v1 }}</span>
                <span class="span1">{{ $t("system.OpeningAmount") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v2 }}</span>
                <span class="span1">{{ $t("system.Replenishmenttimes") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v3 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitmargin") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v4 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Profitstopcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v5 | ratioFormat }}%</span>
                <span class="span1">{{
                  $t("system.Marginofreplenishment")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v6 | ratioFormat }}%</span>
                <span class="span1">{{ $t("system.Makeupcallback") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v7 }}</span>
                <span class="span1">{{
                  $t("system.Horizontalpushornot")
                }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">{{ strategy.v8 }}</span>
                <span class="span1">{{ $t("system.bucangbeishu") }}</span>
              </van-col>
              <van-col span="8" class="strategyItem">
                <span class="span2">x{{ strategy.v9 }}</span>
                <span class="span1">{{ $t("system.gangganbeishu") }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'manual_f_long'">
              <van-col span="12" class="strategyItem">
                <span class="span1">{{ $t("system.gangganbeishu") }}</span>
                <span class="span2">x{{ strategy.v1 }}</span>
              </van-col>
            </van-row>
            <van-row v-if="dataInfo.type === 'manual_f_short'">
              <van-col span="12" class="strategyItem">
                <span class="span1">{{ $t("system.gangganbeishu") }}</span>
                <span class="span2">x{{ strategy.v1 }}</span>
              </van-col>
            </van-row>
            <div class="xian" v-if="dataInfo.type !== 'double'"></div>
            <!-- 止损价格 -->
            <!-- <van-row>
							<van-col span="12" class="strategyItem1">
								<span class="span2" v-if="dataInfo.type === 'wangge' || dataInfo.type === 'wangge2'||
                dataInfo.type == 'dibu_gua' || dataInfo.type === 'wave_f' || dataInfo.type === 'wave_f_coin'">{{ strategy.v9 }}</span>
								<span class="span2" v-if="dataInfo.type === 'wave'">{{ strategy.v7 }}</span>
								<span class="span2" v-if="dataInfo.type === 'wangge_m'">{{ strategy.v16 }}</span>
								<span class="span2" v-if="dataInfo.type === 'wangge_f'">{{ strategy.v11 }}</span>
								<span class="span2" v-if="dataInfo.type === 'manual_f_long' || dataInfo.type === 'manual_f_short'">{{ strategy.v2 }}</span>
								<span class="span1">{{ $t('system.zhisunjiage') }}</span>
							</van-col>
							<van-col span="12" class="strategyItem1" v-if="dataInfo.type === 'manual_f_long' || dataInfo.type === 'manual_f_short'">
								<span class="span2">{{ strategy.v3 }}</span>
								<span class="span1">{{ $t('system.zhiyingjiage') }}</span>
							</van-col>
						</van-row> -->
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type !== 'double'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.zhisunjiage") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span
                  class="span2"
                  v-if="
                    dataInfo.type === 'wangge' ||
                    dataInfo.type === 'wangge2' ||
                    dataInfo.type === 'dibu_gua'||
                    dataInfo.type === 'wave_f' ||
                    dataInfo.type === 'wave_f_coin'
                  "
                  >{{ strategy.v9 }}</span
                >
                <span class="span2" v-if="dataInfo.type === 'wave'">{{
                  strategy.v7
                }}</span>
                <span
                  class="span2"
                  v-if="dataInfo.type === 'wave_f_x_double'"
                  >{{ strategy.v5 }}</span
                >
                <span class="span2" v-if="dataInfo.type === 'wangge_m'">{{
                  strategy.v16
                }}</span>
                <span class="span2" v-if="dataInfo.type === 'wangge_f'">{{
                  strategy.v11
                }}</span>
                <span
                  class="span2"
                  v-if="
                    dataInfo.type === 'manual_f_long' ||
                    dataInfo.type === 'manual_f_short'
                  "
                  >{{ strategy.v2 }}</span
                >
                <span class="span2" v-if="dataInfo.type === 'wangge_x'">{{ strategy.v32 }}</span>
                <!-- <span class="span2" v-if="dataInfo.type === 'double'">{{ strategy.v6 }}</span> -->
              </van-col>
            </van-row>
            <!-- 止盈价格 -->
            <div
              class="xian"
              v-if="
                dataInfo.type === 'manual_f_long' ||
                dataInfo.type === 'manual_f_short'
              "
            ></div>
            <van-row
              type="flex"
              justify="space-between"
              v-if="
                dataInfo.type === 'manual_f_long' ||
                dataInfo.type === 'manual_f_short'
              "
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.zhiyingjiage") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span2">{{ strategy.v3 }}</span>
              </van-col>
            </van-row>

            <!-- 防暴跌-->
            <div
              class="xian"
              v-if="
                dataInfo.type === 'wave' ||
                dataInfo.type === 'wave_f' ||
                dataInfo.type === 'wave_f_coin'
              "
            ></div>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.AntiRiotFall") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span2" v-if="strategy.v8 == 0 || !strategy.v8">{{
                  $t("system.Closed")
                }}</span>
                <span class="span2" v-if="strategy.v8 == 1"
                  >{{ $t("system.CycleTime") }}{{ strategy.v9 }},{{
                    $t("system.DeclineRange")
                  }}{{ strategy.v10 | fangbaodie_filter }}%</span
                >
              </van-col>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave_f'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.AntiRiotFall") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span2" v-if="strategy.v10 == 0 || !strategy.v10">{{
                  $t("system.Closed")
                }}</span>
                <span class="span2" v-if="strategy.v10 == 1"
                  >{{ $t("system.CycleTime") }}{{ strategy.v11 }},{{
                    $t("system.DeclineRange")
                  }}{{ strategy.v12 | fangbaodie_filter }}%</span
                >
              </van-col>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave_f_coin'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.AntiRiotFall") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span2" v-if="strategy.v10 == 0 || !strategy.v10">{{
                  $t("system.Closed")
                }}</span>
                <span class="span2" v-if="strategy.v10 == 1"
                  >{{ $t("system.CycleTime") }}{{ strategy.v11 }},{{
                    $t("system.DeclineRange")
                  }}{{ strategy.v12 | fangbaodie_filter }}%</span
                >
              </van-col>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave_f_x_double'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.AntiRiotFall") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span2" v-if="strategy.v6 == 0">{{
                  $t("system.Closed")
                }}</span>
                <span class="span2" v-if="strategy.v6 == 1"
                  >{{ $t("system.CycleTime") }}{{ strategy.v7 }},{{
                    $t("system.DeclineRange")
                  }}{{ strategy.v8 | fangbaodie_filter }}%</span
                >
              </van-col>
            </van-row>
						<van-row type="flex" justify="space-between" v-if="dataInfo.type === 'wangge_x'">
							<van-col class="strategyItem1">
								<span class="span1">{{ $t('system.AntiRiotFall') }}</span>
							</van-col>
							<van-col class="strategyItem1">
								<span class="span2" v-if="strategy.v33 ==0">{{ $t('system.Closed') }}</span>
								<span class="span2" v-if="strategy.v33 ==1">{{ $t('system.CycleTime') }}{{ strategy.v34 }},{{ $t('system.DeclineRange') }}{{ strategy.v35 | fangbaodie_filter }}%</span>
							</van-col>
						</van-row>
            <!-- 解套 -->
            <div
              class="xian"
              v-if="
                dataInfo.type === 'wave' ||
                dataInfo.type === 'wave_f' ||
                dataInfo.type === 'wave_f_coin'
              "
            ></div>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.UnravelingMode") }}</span>
                <span class="span2" v-if="strategy.v11 == 0 || !strategy.v11">{{
                  $t("system.Closed")
                }}</span>
                <span class="span4" v-if="strategy.v11 == 1">{{
                  $t("system.InOperation")
                }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span3">{{ $t("system.unlock_price") }}</span
                ><span class="span2">{{ dataInfo.jiancang_profit }} USDT</span>
              </van-col>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave_f'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.UnravelingMode") }}</span>
                <span class="span2" v-if="strategy.v13 == 0 || !strategy.v13">{{
                  $t("system.Closed")
                }}</span>
                <span class="span4" v-if="strategy.v13 == 1">{{
                  $t("system.InOperation")
                }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span3">{{ $t("system.unlock_price") }}</span
                ><span class="span2">{{ dataInfo.jiancang_profit }} USDT</span>
              </van-col>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave_f_coin'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.UnravelingMode") }}</span>
                <span class="span2" v-if="strategy.v13 == 0 || !strategy.v13">{{
                  $t("system.Closed")
                }}</span>
                <span class="span4" v-if="strategy.v13 == 1">{{
                  $t("system.InOperation")
                }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span3">{{ $t("system.unlock_price") }}</span
                ><span class="span2">{{ dataInfo.jiancang_profit }} USDT</span>
              </van-col>
            </van-row>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'wave_f_x_double'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.UnravelingMode") }}</span>
                <span class="span2" v-if="strategy.v9 == 0">{{
                  $t("system.Closed")
                }}</span>
                <span class="span4" v-if="strategy.v9 == 1">{{
                  $t("system.InOperation")
                }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span3">{{ $t("system.unlock_price") }}</span
                ><span class="span2">{{ dataInfo.jiancang_profit }} USDT</span>
              </van-col>
            </van-row>
            <!-- 对冲 -->
            <div
              class="xian"
              v-if="dataInfo.type === 'double'"
            ></div>
            <van-row
              type="flex"
              justify="space-between"
              v-if="dataInfo.type === 'double'"
            >
              <van-col class="strategyItem1">
                <span class="span1">{{ $t("system.liquidation") }}</span>
              </van-col>
              <van-col class="strategyItem1">
                <span class="span2" v-if="strategy.v7 == 0">{{
                  $t("system.Closed")
                }}</span>
                <span class="span4" v-if="strategy.v7 == 1 || !strategy.v7">{{
                  $t("system.InOperation")
                }}</span>
              </van-col>
            </van-row>
          </div>

          <div class="contWhiteBox content action">
            <div class="csTitle2">{{ $t("system.Policyoperation") }}</div>
            <van-row type="flex" justify="space-around">
              <van-col span="4" class="actionItem" @click="robotParamSetup">
                <img src="@/assets/imgs/new_color/set.png" />
                <p>{{ $t("system.Policysettings") }}</p>
              </van-col>
              <van-col span="4" class="actionItem" @click="show0 = true">
                <img src="@/assets/imgs/new_color/maichu.png" />
                <p>{{ $t("system.Clearingandselling") }}</p>
              </van-col>
              <van-col
                span="4"
                class="actionItem"
                @click="show3 = true"
                v-if="
                  dataInfo.type === 'wave' ||
                  dataInfo.type === 'wave_f_x_double' ||
                  dataInfo.type === 'wave_f' ||
                  dataInfo.type === 'wave_f_long' ||
                  dataInfo.type === 'wave_f_short'
                "
              >
                <img src="@/assets/imgs/new_color/qingliguadan.png" />
                <p>{{ $t("system.Qingliguadan") }}</p>
              </van-col>
              <van-col
                span="4"
                class="actionItem"
                @click="manualBuchang"
                v-if="
                  dataInfo.type === 'wangge' ||
                  dataInfo.type === 'wangge2' ||
                  dataInfo.type === 'dibu_gua'||
                  dataInfo.type === 'wangge_f' ||
                  dataInfo.type === 'manual_f_long' ||
                  dataInfo.type === 'manual_f_short' ||
                  dataInfo.type === 'wave_f_coin' ||
                  dataInfo.type === 'wangge_x'
                "
              >
                <img src="@/assets/imgs/new_color/bucang.png" />
                <p>{{ $t("system.manualBuchang") }}</p>
              </van-col>
              <van-col
                span="4"
                class="actionItem"
                @click="manualMaichu"
                v-if="
                  dataInfo.type === 'wangge' ||
                  dataInfo.type === 'wangge2' ||
                  dataInfo.type === 'dibu_gua' ||
                  dataInfo.type === 'wangge_f' ||
                  dataInfo.type === 'manual_f_long' ||
                  dataInfo.type === 'manual_f_short' ||
                  dataInfo.type === 'wave_f_coin' ||
                  dataInfo.type === 'wangge_x'
                "
              >
                <img src="@/assets/imgs/new_color/shou_mai.png" />
                <p>{{ $t("system.manualMaichu") }}</p>
              </van-col>
            </van-row>
          </div>
          <div
            class="comBtnStyle"
            @click="toggleBot()"
            style="margin-top: 0.8rem"
          >
            <span>{{ returnBtntag(dataInfo.state) }}</span>
          </div>
          <!-- 一键清仓2次确认 -->
          <van-dialog
            v-model="show0"
            :title="$t('system.tip')"
            show-cancel-button
            :message="$t('system.warehouseClear')"
            @confirm="yes"
          ></van-dialog>
          <!-- 清理挂单2次确认 -->
          <van-dialog
            v-model="show3"
            :title="$t('system.tip')"
            show-cancel-button
            :message="$t('system.orderClear')"
            @confirm="yes3"
          ></van-dialog>
          <!-- 手动补仓对话框 -->
          <van-dialog
            v-model="show"
            show-cancel-button
            :title="$t('system.oneButtonReplenishment1')"
            @confirm="buchang"
            v-if="
              dataInfo.type == 'manual_f_long' ||
              dataInfo.type == 'manual_f_short'
            "
          >
            <div class="dialogStyle">
              <van-field
                :label="$t('system.ReplenishmentQuantity')"
                ref="fieldNum"
                v-model="num"
                autofocus="autofocus"
                class="psdInput"
                type="number"
              />
            </div>
          </van-dialog>
          <!-- 手动补仓对话框 -->
          <van-dialog
            v-model="show"
            show-cancel-button
            :title="$t('system.oneButtonReplenishment')"
            @confirm="buchang"
            v-else
          >
            <div class="dialogStyle">
              <van-field
                :label="$t('system.ReplenishmentQuantity')"
                ref="fieldNum"
                v-model="num"
                autofocus="autofocus"
                class="psdInput"
                type="number"
              />
            </div>
          </van-dialog>
          <!-- 卖出对话框 -->
          <van-dialog
            v-model="show1"
            show-cancel-button
            :title="
              $t('system.manualMaichu') + '(' + $t('system.Contract') + ')'
            "
            @confirm="maichu"
            v-if="fangshi == 2 && dataInfo.type !== 'wave_f_coin'"
          >
            <div class="dialogStyle">
              <van-field
                :label="$t('system.SelloutQuantity')"
                ref="fieldNum"
                v-model="num1"
                autofocus="autofocus"
                class="psdInput"
                type="number"
                @input="input_shu()"
              />
            </div>
          </van-dialog>
          <van-dialog
            v-model="show1"
            show-cancel-button
            :title="$t('system.manualMaichu') + '(' + this.dataInfo.token + ')'"
            @confirm="maichu"
            v-if="fangshi == 2 && dataInfo.type == 'wave_f_coin'"
          >
            <div class="dialogStyle">
              <van-field
                :label="$t('system.SelloutQuantity')"
                ref="fieldNum"
                v-model="num1"
                autofocus="autofocus"
                class="psdInput"
                type="number"
                @input="input_shu()"
              />
            </div>
          </van-dialog>
          <van-dialog
            v-model="show1"
            show-cancel-button
            :title="$t('system.manualMaichu') + '(' + this.dataInfo.token + ')'"
            @confirm="maichu"
            v-if="fangshi == 1"
          >
            <div class="dialogStyle">
              <van-field
                :label="$t('system.SelloutQuantity')"
                ref="fieldNum"
                v-model="num1"
                autofocus="autofocus"
                class="psdInput"
                type="number"
                @input="input_shu()"
              />
            </div>
          </van-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import Decimal from "decimal.js";
export default {
  name: "circularStrategy",
  data() {
    return {
      bot_id: this.$route.query.bot_id,
      homepath: this.$route.query.homepath,
      exchange: this.$route.query.exchange,
      // -------20200414[start]--------
      robot_title: this.$t("system.title"), //标题名字
      dataInfo: {},
      // -------20200414[end]--------
      robot_id: "",
      strategy: {}, //各个参数值
      num: 10, //补仓数量
      show: false, //补仓对话框
      show0: false, //一键清仓2次确认对话框
      show3: false, //清理挂单2次确认对话框
      state: null,
      show1: false,
      num1: 0, //卖出数量
      mai_tital: "",
      fangshi: this.$route.query.fangshi,
    };
  },
  filters: {
    ratioFormat(value) {
      let str = value + "";
      if (str.length > 4) {
        let f = parseFloat(value);
        return f.toFixed(2);
      } else {
        return value;
      }
    },
    danwei(v) {
      if (v) {
        let arr = v.split("/");
        return arr[1];
      }
    },
    danwei3(v) {
      // v = 485.01268;
      let index;
      if (v != 0) {
        if (v <= 0.1) {
          if (v) {
            let b = v.toString().split(".")[1];
            let long = b.length > 3 ? (b[0] == 0 ? 3 : b.length) : b.length;
            for (let key in b) {
              if (b[key] != 0) {
                index = key;
                break;
              }
            }
            return Number(v).toFixed(Number(index) + Number(long));
          }
        } else {
          return v;
        }
      } else {
        return 0;
      }
    },
    fangbaodie_filter(v) {
      return v;
    },
  },
  computed: {
    ...mapGetters(["robotData"]),
  },
  created() {
    if (this.robotData) {
      this.initData(this.robotData);
    } else {
      this.getBot();
    }

    this.robot_title = this.$route.query.robot;
  },
  methods: {
    // 双向对冲计算预估保证金
    computedEstimatedMargin() {
      // 计算方法是 用户的第二个参数最大持仓 * ctvalue / 杠杆倍数(比如5) * 当前价格
      var data = Number(
        new Decimal(this.strategy.v2)
          .mul(new Decimal(this.dataInfo.ctvalue))
          .div(new Decimal(this.strategy.v5))
          .mul(new Decimal(this.dataInfo.last))
      );
      return data;
    },
    onClickLeft() {
      this.$router.back(-1);
    },
    onclickRight() {
      this.$router.push({
        // path: '/transactionRecords',
        path: "/recordsORjournal",
        query: {
          tab: 0,
          currency_pair: this.dataInfo.currency_pair,
          exchange: this.exchange,
          fangshi: this.fangshi,
        },
      });
    },
    input_shu() {
      if (this.num1 > this.dataInfo.num) {
        this.$toast.fail(this.$t("system.cao_num"));
        this.num1 = 0;
      }
    },
    // 返回-启动/停止-字符
    returnBtntag(state) {
      if (state == 0) {
        this.state = 1;
        return this.$t("system.start_up");
      }
      if (state == 1) {
        this.state = 0;
        return this.$t("system.stop_it");
      }
    },
    // 弹出补仓对话框
    manualBuchang() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs.fieldNum.focus();
      });
    },
    // 弹出卖出对话框
    manualMaichu() {
      this.show1 = true;
      this.$nextTick(() => {
        this.$refs.fieldNum.focus();
      });
    },
    // 确定，手动补仓，提交数据
    buchang() {
      this.$post2("Robot/api/index/manualBuchang", {
        bot_id: this.bot_id,
        num: this.num,
      })
        .then((res) => {
          // this.$toast.success('补仓成功');
          this.$toast.success(this.$t("system.SuccessReplenish"));
          this.getBot();
        })
        .catch((e) => {
          // console.log(e, "出错");
          // this.$toast.success('补仓失败');
          this.$toast.success(this.$t("system.failReplenish"));
        });
    },
    // 确定，手动卖出，提交数据
    maichu() {
      this.$post2("Robot/api/index/manualSellOut", {
        bot_id: this.bot_id,
        num: this.num1,
      })
        .then((res) => {
          // this.$toast.success('补仓成功');
          this.$toast.success(this.$t("system.SuccessSellout"));
          this.getBot();
        })
        .catch((e) => {
          // console.log(e, "出错");
          // this.$toast.success('补仓失败');
          this.$toast.success(this.$t("system.failSellout"));
        });
    },

    // 清仓卖出
    sellOut() {
      if (this.dataInfo.state == 1) {
        // this.$toast.fail('请先停止机器人');
        this.$toast.fail(this.$t("system.stopRobotFirst"));
        return;
      }
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        // message: '处理中...'
        message: this.$t("system.Processing"),
      });
      this.$post2("Robot/api/index/sellOut", {
        bot_id: this.bot_id,
      })
        .then((res) => {
          // this.$toast.success('清仓成功');
          toast.clear();
          this.$toast.success(this.$t("system.SuccessClearance"));
          this.getBot();
        })
        .catch((e) => {
          // console.log(e, "出错");
          // this.$toast.success('清仓失败');
          toast.clear();
          this.$toast.success(this.$t("system.failClearance"));
        });
    },
    // 清理挂单
    qingGua() {
      if (this.dataInfo.state == 1) {
        // this.$toast.fail('请先停止机器人');
        this.$toast.fail(this.$t("system.stopRobotFirst"));
        return;
      }
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        // message: '处理中...'
        message: this.$t("system.Processing"),
      });
      this.$post2("Robot/api/index/clearHangingOrder", {
        bot_id: this.bot_id,
      })
        .then((res) => {
          toast.clear();
          this.$toast.success(this.$t("system.Successqinggua"));
          this.getBot();
        })
        .catch((e) => {
          toast.clear();
          this.$toast.success(this.$t("system.failqinggua"));
        });
    },

    // 策略设置
    robotParamSetup() {
      this.$router.replace({
        path: "robotSetup",
        query: {
          bot_id: this.dataInfo.bot_id,
          exchange: this.dataInfo.web,
          type: this.dataInfo.type,
          fangshi: this.fangshi,
          // lastpath: this.$route.fullPath,
        },
      });
    },

    // 停止/启动-设置按钮
    toggleBot() {
      let toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        // message: '处理中...'
        message: this.$t("system.Processing"),
      });
      this.$post2("Robot/api/index/toggleBot", {
        bot_id: this.bot_id,
        state: this.state,
        noToast: true,
      })
        .then((res) => {
          if (this.state == 1) {
            this.$toast.success(this.$t("system.qidongsuccess"));
          } else if (this.state == 0) {
            this.$toast.success(this.$t("system.StopSuccess"));
          }
          this.getBot();
          toast.clear();
        })
        .catch((e) => {
          // console.log(e, '出错');
          toast.clear();
          if (e == "用户燃料费不足") {
            this.$toast.fail(this.$t("system.gasInsufficient"));
            return;
          }
        });
    },

    // 初始化-获取详情信息
    getBot() {
      var toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        // message: '加载中...'
        message: this.$t("system.Loading"),
      });
      this.$post2("Robot/api/index/getBotV2", {
        bot_id: this.bot_id,
        noToast: true,
      })
        .then((res) => {
          // 更新选中项机器人参数
          this.$store.commit("setRobotData", res);

          this.initData(res);
          toast.clear();
        })
        .catch((e) => {
          // console.log(e, "出错");
          // this.$toast.fail('获取机器人失败');
          this.$toast.fail(this.$t("system.FailedAcquireRobot"));
          toast.clear();
        });

      // 更新首页列表
      this.$store.dispatch("setExchangeRobotList");
      // 更新量化列表
      this.$store.dispatch("setRobotList");
      // .then((res) => {
      //   // 更新选中项机器人参数
      //   try {
      //     res.list.forEach((item) => {
      //       if (item.bot_id == this.bot_id) {
      //         var robotData = {
      //           ...item,
      //           currency_pair: item.token,
      //           token: item.token.split("/")[0],
      //         };
      //         this.$store.commit("setRobotData", robotData);
      //         this.initData(robotData);
      //         throw Error();
      //       }
      //       //   toast.clear();
      //     });
      //   } catch (error) {
      //     // toast.clear();
      //   }
      // })
      // .catch((err) => {
      //   //   toast.clear();
      // });
    },
    // 初始化数据 更新数据
    initData(res) {
      this.dataInfo = res;
      this.strategy = res.param_arr;
      if (res.type == "wangge") {
        if (!this.strategy.v8) {
          this.strategy.v8 = 2;
        }
        if (!this.strategy.v9) {
          this.strategy.v9 = 0;
        }
      }
      if (
        res.type == "wangge2"||
        res.type == 'dibu_gua'
      ) {
        if (!this.strategy.v9) {
          this.strategy.v9 = 0;
        }
      }
      if (res.type == "wave") {
        if (!this.strategy.v7) {
          this.strategy.v7 = 0;
        }
      }
      if (res.type == "wangge_m") {
        if (!this.strategy.v16) {
          this.strategy.v16 = 0;
        }
      }
      if (res.type == "wave_f") {
        if (!this.strategy.v9) {
          this.strategy.v9 = 0;
        }
      }
      if (res.type == "wangge_f") {
        if (!this.strategy.v8) {
          this.strategy.v8 = 2;
        }
        if (!this.strategy.v11) {
          this.strategy.v11 = 0;
        }
      }
      if (res.type == "wave_f_coin") {
        if (!this.strategy.v9) {
          this.strategy.v9 = 0;
        }
      }
      if (res.type == "wave_f_x_double") {
        if (!this.strategy.v5) {
          this.strategy.v5 = 0;
        }
      }
      if (res.type == "double") {
        if (!this.strategy.v6) {
          this.strategy.v6 = 0;
        }
      }
    },
    yes() {
      this.sellOut();
    },
    yes3() {
      this.qingGua();
    },
  },
};
</script>

<style lang="less" scoped>
.circularStrategy {
  font-family: PingFangSC-Regular;

  .content {
    padding: 0.25rem;

    .csTitle {
      font-family: PingFangSC-Medium;
      color: #333;
      font-size: 0.28rem;
      // font-family: Bahnschrift;
      font-weight: bold;
      height: 0.63rem;
      display: flex;
      align-items: center;
      padding-bottom: 0.25rem;
      margin-bottom: 0.2rem;
      border-bottom: 0.01rem solid rgba(211, 217, 222, 0.49);

      span {
        font-size: 0.24rem;
        transform: scale(0.83);
        padding: 0 0.1rem;
        line-height: 0.36rem;
        background: #f1f7ff;
        border-radius: 0.04rem;
        display: inline-block;
        margin-left: 0.05rem;
        color: #6f7a8b;

        &.gray {
          color: #9b9b9b;
          background: #f5f5f5;
        }

        &.gray1 {
          padding: 0.02rem 0.13rem;
          border: none;
          color: #f5465d;
          background: rgba(245, 70, 93, 0.1);
        }

        &.gray2 {
          padding: 0.02rem 0.13rem;
          border: none;
          color: #32bf88;
          background: rgba(50, 191, 136, 0.09);
        }
      }
    }
  }

  .strategy {
    .strategyItem {
      display: flex;
      flex-direction: column;
      padding: 0.1rem 0.2rem;
      margin-bottom: 0.1rem;

      &:last-child {
        padding-bottom: 0.2rem;
        margin-bottom: 0;
      }

      &:nth-of-type(3) {
        padding-left: 0.5rem;
        padding-right: 0;
      }

      &:nth-of-type(6) {
        padding-left: 0.5rem;
        padding-right: 0;
      }
    }

    .xian {
      padding: 0.01rem 0.2rem;
      border-top: 0.01rem solid #f5f5f5;
    }

    .strategyItem1 {
      padding: 0.2rem 0.2rem 0.2rem 0.2rem;
      display: flex;
      align-items: center;

      // margin-bottom: .1rem;
      &:last-child {
      }

      .span1 {
        font-size: 0.28rem;
        color: #6f7989;
        margin-right: 0.12rem;
      }
    }

    .span1 {
      font-size: 0.24rem;
      color: #666666;
      margin-right: 0.12rem;
    }

    .span2 {
      font-size: 0.28rem;
      // font-family: Bahnschrift;
      font-weight: 550;
      color: #333333;
    }

    .span3 {
      font-size: 0.28rem;
      color: #6f7989;
      margin-right: 0.2rem;
    }

    .span4 {
      font-size: 0.28rem;
      color: #32bf88;
      font-weight: 550;
      margin-right: 0.2rem;
    }
  }

  .action {
    // padding-bottom: 1rem;

    .actionItem {
      // margin-top: 0.55rem;
      padding-top: 0.1rem;
      text-align: center;

      img {
        height: 0.6rem;
        display: block;
        margin: auto;
      }

      p {
        margin-top: 0.12rem;
        font-size: 0.24rem;
        color: #666666;
      }
    }

    .actionButton {
      height: 0.79rem;
      width: 69%;
      margin: 1rem auto 0;
      background: #ff4b04;
      border-radius: 100px;
      font-size: 0.31rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 0.78rem;
      text-align: center;
    }
  }
}

// ------------20200412[start]----------------
.err_msg {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: rgba(245, 70, 93, 0.1);
  color: #f5465d;
  font-size: 0.24rem;
  text-align: center;
}

.msg_list {
  padding: 0.2rem 0.3rem 0.25rem 0.3rem;
}

.layoutBox {
  min-height: calc(100vh - 1.76rem);
}

.head_list {
  display: flex;
  justify-content: space-between;

  .numItem {
    width: 50%;
    margin-left: 0.2rem;
    text-align: left;
    margin-bottom: 0.3rem;

    p {
      color: #666666;
      font-size: 0.24rem;
      margin-bottom: 0.1rem;
    }

    span {
      color: #373737;
      // font-family: Bahnschrift;
      font-size: 0.32rem;
    }
  }

  .numItem1 {
    width: 50%;
    margin-left: 0.2rem;
    text-align: left;
    margin-bottom: 0.3rem;

    p {
      color: #6f7a8b;
      font-size: 0.24rem;
      margin-bottom: 0.1rem;
    }

    span {
      color: #333333;
      // font-family: Bahnschrift;
      font-size: 0.32rem;
    }
  }

  .numItem2 {
    width: 25%;
    margin-left: 0.2rem;
    text-align: left;
    margin-bottom: 0.3rem;

    p {
      color: #6f7a8b;
      font-size: 0.24rem;
      margin-bottom: 0.1rem;
    }

    span {
      color: #333333;
      // font-family: Bahnschrift;
      font-size: 0.32rem;
    }
  }

  .numItem3 {
    // width: 30%;
    // margin-left: 0.2rem;
    width: 40%;
    text-align: left;
    margin-bottom: 0.3rem;

    p {
      color: #6f7a8b;
      font-size: 0.24rem;
      margin-bottom: 0.1rem;
    }

    span {
      color: #333333;
      // font-family: Bahnschrift;
      font-size: 0.32rem;
    }
  }
}

.csTitle1 {
  border-top: 0.01rem solid rgba(211, 217, 222, 0.5);
  padding: 0.2rem 0 0 0;
  color: #333333;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  span {
    font-weight: bold;
  }
}

.csTitle2 {
  font-family: PingFangSC-Medium;
  padding-bottom: 0.2rem;
  color: #333333;
  font-size: 0.28rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  margin-bottom: 0.2rem;

  span {
    font-weight: bold;
  }
}

.contWhiteBox {
  border-radius: 0.16rem;
}

.comBtnStyle {
  font-family: Regular;
  background: #fad538;
  color: #333333;
  font-size: 0.32rem;
}

// ------------20200412[end]----------------
</style>
